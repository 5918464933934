<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <SumHeaderTable
      :value="products"
      :global-filter-fields="['keywordText']"
      :columns="columns"
      :sums="sums"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SumHeaderTable from '../../../../SumHeaderTable.vue';
import generateCommonColumns from '../../../../../utils/tableUtils';

export default {
  components: { SumHeaderTable },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/no-unused-properties
    dateRange: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    sums: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          id: 'productAsin',
          field: 'asin',
          header: 'ASIN',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '150px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: null,
          },
        },
        {
          id: 'productState',
          field: 'state',
          header: 'Status',
          width: '100px',
          sortable: true,
          numeric: false,
          decimal: false,
          content: {
            center: false,
            to: null,
            badge: true,
            format: null,
          },
        },
        ...generateCommonColumns('keywords', () => this.royalties, () => this.$store.state.auth.selectedProfile),
      ],
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
